<ng-select #ngSelect
           [(ngModel)]="category"
           (ngModelChange)="categoryChange.emit(category)"
           class="redesign entity-dropdown"
           bindLabel="name"
           [items]="categories$ | async"
           appendTo="body"
           [placeholder]="'CUSTOMER.CATEGORY' | translate">
  <ng-template ng-footer-tmp>
    <omt-dropdown-footer [tooltip]="'CUSTOMER.NEW_CATEGORY' | translate" (addClick)="openDialog(); ngSelect.close()"></omt-dropdown-footer>
  </ng-template>
</ng-select>
