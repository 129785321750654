import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {
    CustomerCreateReferencePersonModalComponent
} from '../customer-create-reference-person-modal/customer-create-reference-person-modal.component';
import {CustomerUserModel} from '@customer/components/customer-detail/customer-accounts/customer-user.model';

@Component({
    selector: 'omt-customer-create-user-dialog',
    templateUrl: './customer-create-user-dialog.component.html',
    styleUrls: []
})
export class CustomerCreateUserDialogComponent {
    // -----------------------------------------------------------------------------------------------------
    // @ Declarations
    // -----------------------------------------------------------------------------------------------------

    @Output() userCreated = new EventEmitter<CustomerUserModel>();
    @Output() userEdited = new EventEmitter<CustomerUserModel>();

    customerUser: CustomerUserModel;
    passwordRepeated: string;
    isNewUser = true;

    constructor(private readonly dialogRef: MatDialogRef<CustomerCreateReferencePersonModalComponent>,
                private readonly _formBuilder: UntypedFormBuilder,
                @Inject(MAT_DIALOG_DATA) private readonly data: CustomerUserModel) {
        if (data) {
            this.customerUser = data;
            this.isNewUser = false;
            this.customerUser.passwordHash = '';
        } else {
            this.customerUser = new CustomerUserModel();
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    save(): void {
        if (this.isNewUser) {
            this.userCreated.emit(this.customerUser);
        } else {
            this.userEdited.emit(this.customerUser);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    closeDialog(): void {
        this.dialogRef.close();
    }
}
