<omt-container-with-sidenav>
  <mat-list sidenav>
    <mat-list-item>
      <button mat-stroked-button (click)="activeComponent = propositionEnum.HOURLY_RATE" [class.active]="activeComponent === propositionEnum.HOURLY_RATE">Stundensätze
      </button>
    </mat-list-item>

    <mat-list-item>
      <button mat-stroked-button (click)="activeComponent = propositionEnum.CUSTOMER_REFS" [class.active]="activeComponent === propositionEnum.CUSTOMER_REFS">
        Kundenreferenzen
      </button>
    </mat-list-item>
  </mat-list>

  <ng-container main>
    @switch (activeComponent) {
      @case (propositionEnum.HOURLY_RATE) {
        <omt-customer-hourly-rate [customerId]="customerId"></omt-customer-hourly-rate>
      }
      @case (propositionEnum.CUSTOMER_REFS) {
        <omt-customer-references [customerId]="customerId"></omt-customer-references>
      }
    }
  </ng-container>
</omt-container-with-sidenav>
