import {Component, EventEmitter, Inject, OnDestroy, Output} from '@angular/core';
import {LayoutConst} from '@core/constants/layout';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

import {TranslateService} from '@ngx-translate/core';
import {throwError} from 'rxjs';
import {SubSink} from 'subsink';
import {WorkLogTypeByCustomerModel} from '@ticket/models';
import {WorklogTypeService} from '@management/tickets/work-log-types/worklog-type.service';
import {SnackbarService} from '@core/services/snackbar.service';

@Component({
    selector: 'omt-customer-hourly-rate-dialog',
    templateUrl: './customer-hourly-rate-dialog.component.html',
    styleUrls: ['./customer-hourly-rate-dialog.component.scss']
})
export class CustomerHourlyRateDialogComponent implements OnDestroy {
    @Output() newWorkLogRate = new EventEmitter<WorkLogTypeByCustomerModel>();

    @Output() updatedWorkLogRate = new EventEmitter<WorkLogTypeByCustomerModel>();

    layout = LayoutConst;
    workLogRate: WorkLogTypeByCustomerModel;
    $workLogTypes = this.worklogTypeService.getAll();

    private subs = new SubSink();

    constructor(
        public dialogRef: MatDialogRef<CustomerHourlyRateDialogComponent>,
        private readonly worklogTypeService: WorklogTypeService,
        private readonly snackbarService: SnackbarService,
        private readonly translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public dialogData: CustomerHourlyRateDialogData
    ) {
        if (!dialogData.customerId) {
            throwError('Keine Customer-ID angegeben!');
        }

        this.workLogRate = dialogData.editItem ?? new WorkLogTypeByCustomerModel();
        this.workLogRate.customerId = dialogData.customerId;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Business Logic
    // -----------------------------------------------------------------------------------------------------

    save(): void {
        if (!this.workLogRate.workLogType) {
            this.snackbarService.open(this.translateService.instant('Der Arbeitszeit-Typ fehlt.'));
            return;
        }

        if (!this.workLogRate.hourlyRate) {
            this.snackbarService.open(this.translateService.instant('Der Stundensatz fehlt.'));
            return;
        }

        if (!this.workLogRate.id) {
            this.worklogTypeService.createWorkLogTypeByCustomer(this.workLogRate).subscribe((data: WorkLogTypeByCustomerModel) => {
                this.newWorkLogRate.emit(data);
                this.closeDialog();
            });
        } else {
            this.worklogTypeService.updateWorkLogTypeByCustomer(this.workLogRate).subscribe((data: WorkLogTypeByCustomerModel) => {
                this.updatedWorkLogRate.emit(data);
                this.closeDialog();
            });
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    closeDialog(): void {
        this.dialogRef.close();
    }
}

export class CustomerHourlyRateDialogData {
    editItem?: WorkLogTypeByCustomerModel;
    customerId: number;
}
