@if (!item.hidden) {
  <!-- normal collapsable -->
  @if (!item.url && !item.function) {
    <a class="nav-link" [ngClass]="item.classes" (click)="toggleOpen($event)">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.url -->
  @if (item.url && !item.externalUrl && !item.function) {
    <a
      class="nav-link"
      [ngClass]="item.classes"
      (click)="toggleOpen($event)"
      [routerLink]="[item.url]"
      [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.externalUrl -->
  @if (item.url && item.externalUrl && !item.function) {
    <a
      class="nav-link"
      [ngClass]="item.classes"
      (click)="toggleOpen($event)"
      [href]="item.url"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.function -->
  @if (!item.url && item.function) {
    <span class="nav-link" [ngClass]="item.classes" (click)="toggleOpen($event); item.function()">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>
  }
  <!-- item.url && item.function -->
  @if (item.url && !item.externalUrl && item.function) {
    <a
      class="nav-link"
      [ngClass]="item.classes"
      (click)="toggleOpen($event); item.function()"
      [routerLink]="[item.url]"
      [routerLinkActive]="['active', 'accent']"
      [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <!-- item.externalUrl && item.function -->
  @if (item.url && item.externalUrl && item.function) {
    <a
      class="nav-link"
      [ngClass]="item.classes"
      (click)="toggleOpen($event); item.function()"
      [href]="item.url"
      [target]="item.openInNewTab ? '_blank' : '_self'"
    >
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
  }
  <ng-template #itemContent>
    @if (item.icon && !item.svg) {
      <mat-icon class="nav-link-icon" [style.color]="'#' + item.colorCode">{{ item.icon }}</mat-icon>
    }
    @if (item.icon && item.svg) {
      <img [id]="item.id" src="{{ clientUrl }}icons/custom/{{ item.icon }}.svg" class="nav-link-icon"/>
    }
    <span class="nav-link-title">{{ item.title }}</span>
    @if (item.badge) {
      <span class="nav-link-badge" [ngStyle]="{'background-color': item.badge.bg, 'color': item.badge.fg}">
        {{ item.badge.title }}
      </span>
    }
    <mat-icon class="collapsable-arrow">keyboard_arrow_right</mat-icon>
  </ng-template>
  <div class="children" [@slideInOut]="isOpen">
    @for (item of item.children; track item.id) {
      @if (item.type === 'item') {
        <fuse-nav-vertical-item [item]="item"></fuse-nav-vertical-item>
      }
      @if (item.type === 'collapsable') {
        <fuse-nav-vertical-collapsable [item]="item"></fuse-nav-vertical-collapsable>
      }
      @if (item.type === 'group') {
        <fuse-nav-vertical-group [item]="item"></fuse-nav-vertical-group>
      }
    }
  </div>
}
