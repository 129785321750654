import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {NoteModel} from '@core/components/note/note.model';

export interface NoteTypeOption<T> {
    name: string;
    value: T;
}

@Component({
    selector: 'omt-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss']
})
export class NoteComponent<T> {
    @Input() set notes(notes: NoteModel<T>[]) {
        this._notes = notes;
    }

    get notes(): NoteModel<T>[] {
        return this._notes;
    }

    private _notes: NoteModel<T>[];

    @Input() canCreate = true;
    @Input() hasTitle = false;
    @Input() hasRichEditor = false;
    @Input() hasType = false;
    @Input() types: NoteTypeOption<T>[];
    @Input() typeTemplate: TemplateRef<any>;
    @Input() noteMaxLength = 1000;
    @Input() titleMaxLength = 100;

    @Output() deleteClicked = new EventEmitter<NoteModel<T>>();
    @Output() createdNote = new EventEmitter<NoteModel<T>>();

    get disabled(): boolean {
        return (this.hasTitle && !this.newNote.title) || !this.newNote.text;
    }

    newNote = new NoteModel<T>();

    delete(note: NoteModel<T>): void {
        this.deleteClicked.emit(note);
    }

    create(): void {
        this.createdNote.emit(this.newNote);
        this.newNote = new NoteModel<T>();
    }
}
