import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {CoreModule} from '@core/core.module';
import {FuseSearchBarModule} from '@fuse/components';
import {NgSelectModule} from '@ng-select/ng-select';
import {CreateCustomerCategoryDialogComponent} from './components/create-customer-category-dialog/create-customer-category-dialog.component';
import {CustomerAttachmentDialogComponent} from './components/customer-attachment-dialog/customer-attachment-dialog.component';
import {CustomerCreateModalComponent} from './components/customer-create-modal/customer-create-modal.component';
import {CustomerCreateReferencePersonModalComponent} from './components/customer-create-reference-person-modal/customer-create-reference-person-modal.component';
import {CustomerCreateUserDialogComponent} from './components/customer-create-user-dialog/customer-create-user-dialog.component';
import {CustomerAccountsComponent} from './components/customer-detail/customer-accounts/customer-accounts.component';
import {CustomerAttachmentComponent} from './components/customer-detail/customer-attachment/customer-attachment.component';
import {CustomerContactComponent} from './components/customer-detail/customer-contact/customer-contact.component';
import {CustomerDetailComponent} from './components/customer-detail/customer-detail.component';
import {CustomerNoteComponent} from './components/customer-detail/customer-note/customer-note.component';
import {CustomerPriceGroupsComponent} from './components/customer-detail/customer-price-groups/customer-price-groups.component';
import {CustomerProjectListComponent} from './components/customer-detail/customer-project-list/customer-project-list.component';
import {CustomerReferencePersonComponent} from './components/customer-detail/customer-reference-person/customer-reference-person.component';
import {IssueListComponent} from './components/customer-detail/issue-list/issue-list.component';
import {CustomerComponent} from './components/customer.component';
import {CustomerRoutingModule} from './customer-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {ProjectModule} from '@management/tickets/project/project.module';
import {CustomerHourlyRateDialogComponent} from './components/customer-detail/hourly-rate/customer-hourly-rate-dialog/customer-hourly-rate-dialog.component';
import {HourlyRateHostComponent} from './components/customer-detail/hourly-rate/hourly-rate-host.component';
import {CustomerReferencesComponent} from './components/customer-detail/hourly-rate/customer-references/customer-references.component';
import {CustomerHourlyRateComponent} from '@customer/components/customer-detail/hourly-rate/customer-hourly-rate/customer-hourly-rate.component';
import {SpinnerModule} from '@libs/ui/src/components/spinner/spinner.module';
import {HeaderModule} from '@core/components/header/header.module';
import {SearchInputModule} from '@libs/ui/src/components/search-input/search-input.module';
import {HelpIconModule} from '@libs/ui/src/components/help-icon/help-icon.module';
import {ImageCropperModule} from '@core/components/image-cropper/image-cropper.module';
import {NoteModule} from '@core/components/note/note.module';
import {DialogHostModule} from '@libs/ui/src/components/dialog-host/dialog-host.module';
import {SharedModule} from '@shared/shared.module';
import {NumberRangeKeyModule} from '@libs/ui/src/components/number-range-key/number-range-key.module';
import {CustomerReferencesDialogModule} from '@customer/components/customer-detail/hourly-rate/customer-references-dialog/customer-references-dialog.module';
import {PaymentConditionDropdownModule} from '@shared/components/dropdowns/payment-condition-dropdown/payment-condition-dropdown.module';
import {PaymentTypeDropdownModule} from '@shared/components/dropdowns/payment-type-dropdown/payment-type-dropdown.module';
import {PaymentTypeDialogModule} from '@management/customer/payment-type/payment-type-dialog.module';
import {PaymentConditionDialogModule} from '@management/customer/payment-condition/payment-condition-dialog.module';
import {ContainerWithSidenavModule} from '@core/layouts/components/container-with-sidenav/container-with-sidenav.module';
import {CustomerCategoryDropdownModule} from '@customer/components/dropdowns/customer-category-dropdown/customer-category-dropdown.module';
import {MissingEntriesInfoModule} from '@core/components/missing-entries-info/missing-entries-info.module';
import {AvatarModule, BookmarkButtonModule} from '@libs/ui/src';
import {WorklogTypeDropdownModule} from '@ticket/work-log/worklog-type-dropdown/worklog-type-dropdown.module';
import {FavoriteButtonComponent} from '@core/components/favorites/favorite-button.component';
import {FormInputModule} from '@core/components/form-input/form-input.module';
import {SectorDropdownComponent} from '@core/components/sector/sector-dropdown.component';
import {SubSectorDropdownComponent} from '@core/components/sector/sub-sector-dropdown.component';
import {DropzoneModule} from '@core/components/dropzone/dropzone.module';

@NgModule({
    declarations: [
        CustomerComponent,
        IssueListComponent,
        CustomerNoteComponent,
        CustomerDetailComponent,
        CustomerCreateReferencePersonModalComponent,
        CustomerCreateModalComponent,
        CustomerContactComponent,
        CustomerReferencePersonComponent,
        CustomerPriceGroupsComponent,
        CustomerAccountsComponent,
        CustomerCreateUserDialogComponent,
        CustomerAttachmentComponent,
        CustomerProjectListComponent,
        CreateCustomerCategoryDialogComponent,
        CustomerAttachmentDialogComponent,
        CustomerHourlyRateComponent,
        CustomerHourlyRateDialogComponent,
        HourlyRateHostComponent,
        CustomerReferencesComponent
    ],
    imports: [
        CustomerRoutingModule,
        CommonModule,
        CoreModule,
        FormsModule,

        NgSelectModule,
        FlexLayoutModule,

        // Fuse
        FuseSearchBarModule,

        // Material
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatDialogModule,
        MatButtonModule,
        MatRadioModule,
        MatIconModule,
        MatTabsModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatBadgeModule,
        MatCardModule,
        MatDividerModule,
        MatInputModule,
        ReactiveFormsModule,
        MatListModule,
        MatSlideToggleModule,
        DropzoneModule,
        MatPaginatorModule,
        TranslateModule,
        ProjectModule,
        SpinnerModule,
        HeaderModule,
        SearchInputModule,
        HelpIconModule,
        ImageCropperModule,
        NoteModule,
        DialogHostModule,
        SharedModule,
        NumberRangeKeyModule,
        CustomerReferencesDialogModule,
        PaymentTypeDialogModule,
        PaymentConditionDialogModule,
        CustomerCategoryDropdownModule,
        PaymentConditionDropdownModule,
        PaymentTypeDropdownModule,
        ContainerWithSidenavModule,
        MissingEntriesInfoModule,
        AvatarModule,
        WorklogTypeDropdownModule,
        BookmarkButtonModule,
        FavoriteButtonComponent,
        FormInputModule,
        SectorDropdownComponent,
        SubSectorDropdownComponent,
        DropzoneModule
    ]
})
export class CustomerModule {
}
