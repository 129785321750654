@if (isLoading) {
  <omt-spinner></omt-spinner>
}

<div fxLayout="column" class="bg-white p-2">
  <div fxLayout="row" fxLayoutAlign="end">
    <button (click)="openDialog()" mat-fab color="primary">
      <i class="fas fa-plus fa-2x"></i>
    </button>
  </div>


  @if (!isLoading && dataSource) {
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" [trackBy]="'id' | trackByProperty" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let customerRef">
            {{ customerRef.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let customerRef">
            <div class="action-buttons">
              <i class="fa fa-pen" [matTooltip]="'GLOBAL.EDIT' | translate" (click)="openDialog(customerRef)"></i>
              <i class="fa fa-trash" [matTooltip]="'GLOBAL.DELETE' | translate" (click)="deleteRef(customerRef)"></i>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  } @else {
    <span>Keine Kundenreferenzen vorhanden.</span>
  }
</div>
