import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentTypeDialogComponent} from '@management/customer/payment-type/payment-type-dialog.component';
import {DialogHostModule} from '@libs/ui/src';
import {FormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [PaymentTypeDialogComponent],
    imports: [
        CommonModule,
        DialogHostModule,
        FormsModule,
        MatButtonModule,
        TranslateModule
    ]
})
export class PaymentTypeDialogModule {
}
