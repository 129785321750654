<mat-card fxLayout="column" fxLayoutGap="30px" class="p-4 vh-70">
  @if (customer && customer.paymentInfo) {
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.xs="20px">
      <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.lg="33">
        @if (!editMode) {
          <span class="text-muted">{{ 'CUSTOMER.CUSTOMER_ACCOUNT' | translate }}</span>
          <span>{{ customer.paymentInfo.debtorAccount }}</span>
        }
        @if (editMode) {
          <div fxLayout="column" [ngClass.gt-md]="{'w-300': true}" [ngClass.lt-lg]="{'w-200': true}">
            <label>{{ 'CUSTOMER.CUSTOMER_ACCOUNT' | translate }}</label>
            <input class="redesign" [placeholder]="'CUSTOMER.CUSTOMER_ACCOUNT' | translate" [(ngModel)]="customer.paymentInfo.debtorAccount" maxlength="50"/>
          </div>
        }
      </div>
      <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.lg="33">
        @if (!editMode) {
          <span class="text-muted">{{ 'WAREHOUSE.PRICE_GROUP' | translate }}</span>
          @if (customer.paymentInfo.warehousePriceGroup) {
            <span>{{ customer.paymentInfo.warehousePriceGroup.name | empty }}</span>
          }
        }
        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'WAREHOUSE.PRICE_GROUP' | translate }}</label>
            <ng-select
              class="redesign"
              [(ngModel)]="customer.paymentInfo.warehousePriceGroup"
              bindLabel="name"
              [items]="priceGroups"
              [placeholder]="'WAREHOUSE.PRICE_GROUP' | translate"
              [ngClass.gt-md]="{'w-300': true}"
              [ngClass.lt-lg]="{'w-200': true}"
              appendTo="body"
            >
            </ng-select>
          </div>
        }
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.xs="20px">
      <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.lg="33">
        @if (!editMode) {
          <span class="text-muted">{{ 'CUSTOMER.DISCOUNT' | translate }} %</span>
          <span>{{ customer.paymentInfo.discount }}</span>
        }
        @if (editMode) {
          <div fxLayout="column" [ngClass.gt-md]="{'w-300': true}" [ngClass.lt-lg]="{'w-200': true}">
            <label>{{ 'CUSTOMER.DISCOUNT' | translate }} %</label>
            <input class="redesign" [placeholder]="'CUSTOMER.DISCOUNT' | translate" [(ngModel)]="customer.paymentInfo.discount" maxlength="100"/>
          </div>
        }
      </div>
      <div fxLayout="column" fxFlex.lt-lg="50" fxFlex.lg="33">
        @if (!editMode) {
          <span class="text-muted">{{ 'CUSTOMER.PAYMENT_METHOD' | translate }}</span>
          @if (customer.paymentInfo.paymentType) {
            <span>{{ customer.paymentInfo.paymentType.name }}</span>
          }
        }
        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.PAYMENT_METHOD' | translate }}</label>
            <omt-payment-type-dropdown [(paymentType)]="customer.paymentInfo.paymentType"
                                       [ngClass.gt-md]="{'w-300': true}"
                                       [ngClass.lt-lg]="{'w-200': true}"></omt-payment-type-dropdown>
          </div>
        }
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.xs="20px">
      <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.lg="33">
        @if (!editMode) {
          <span class="text-muted">Bank</span>
          <span>{{ customer.paymentInfo.bank }}</span>
        }
        @if (editMode) {
          <div fxLayout="column" [ngClass.gt-md]="{'w-300': true}" [ngClass.lt-lg]="{'w-200': true}">
            <label>Bank</label>
            <input class="redesign" placeholder="Bank" [(ngModel)]="customer.paymentInfo.bank" maxlength="100"/>
          </div>
        }
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.xs="20px">
      <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.lg="33">
        @if (!editMode) {
          <span class="text-muted">IBAN</span>
          <span>{{ customer.paymentInfo.iban }}</span>
        }
        @if (editMode) {
          <div fxLayout="column" [ngClass.gt-md]="{'w-300': true}" [ngClass.lt-lg]="{'w-200': true}">
            <label>IBAN</label>
            <input class="redesign" placeholder="IBAN" [(ngModel)]="customer.paymentInfo.iban" maxlength="100"/>
          </div>
        }
      </div>
      <div fxLayout="column" fxFlex.lt-lg="50" fxFlex.lg="33">
        @if (!editMode) {
          <span class="text-muted">BIC</span>
          <span>{{ customer.paymentInfo.bic }}</span>
        }
        @if (editMode) {
          <div fxLayout="column" [ngClass.gt-md]="{'w-300': true}" [ngClass.lt-lg]="{'w-200': true}">
            <label>BIC</label>
            <input class="redesign" placeholder="BIC" [(ngModel)]="customer.paymentInfo.bic" maxlength="100"/>
          </div>
        }
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.xs="20px">
      <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.lg="33" fxLayoutGap="15px">
        @if (!editMode) {
          <div fxLayout="column">
            <span class="text-muted">{{ 'CUSTOMER.TERM_OF_PAYMENT' | translate }}</span>
            @if (customer.paymentInfo.paymentCondition) {
              <span>{{ customer.paymentInfo.paymentCondition.name }}</span>
            }
          </div>
        }
        @if (editMode) {
          <div fxLayout="column">
            <label>{{ 'CUSTOMER.TERM_OF_PAYMENT' | translate }}</label>
            <omt-payment-condition-dropdown [(paymentCondition)]="customer.paymentInfo.paymentCondition"
                                            [ngClass.gt-md]="{'w-300': true}"
                                            [ngClass.lt-lg]="{'w-200': true}"></omt-payment-condition-dropdown>
          </div>
        }
        @if (customer.paymentInfo.paymentCondition) {
          <span>{{ customer.paymentInfo.paymentCondition.description }}</span>
        }
      </div>
    </div>
  }
</mat-card>
