import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SubSectorCreateRequest, SubSectorModel} from '@core/models/sector.model';
import {SectorService} from '@core/services/global/sector.service';
import {Observable, switchMap} from 'rxjs';
import {EntityDropdown} from '@core/components/entity-dropdown/entity-dropdown.component';
import {AsyncPipe} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '@core/core.module';
import {DialogService} from '@core/services/global/dialog.service';
import {SharedModule} from '@shared/shared.module';

@Component({
    selector: 'omt-sub-sector-dropdown',
    standalone: true,
    imports: [
        AsyncPipe,
        NgSelectModule,
        FormsModule,
        CoreModule,
        SharedModule
    ],
    templateUrl: './sub-sector-dropdown.component.html',
    styleUrl: './sub-sector-dropdown.component.scss'
})
export class SubSectorDropdownComponent extends EntityDropdown implements OnChanges {
    @Input() sectorId: number;
    @Input() selectedSubSector: SubSectorModel;
    @Output() selectedSubSectorChange = new EventEmitter<SubSectorModel>();

    subSectors$: Observable<SubSectorModel[]>;


    constructor(private readonly sectorService: SectorService,
                private readonly dialogService: DialogService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sectorId) {
            this.subSectors$ = this.sectorService.getSubSectors(this.sectorId);
        }
    }

    openDialog(): void {
        const dialogRef = this.dialogService.textEditDialog('Neue Sub-Branche erstellen', '', '');

        this.subs.sink = dialogRef.componentInstance.saveClicked
            .pipe(switchMap((value) => this.sectorService.createSubSector(new SubSectorCreateRequest(value, this.sectorId))))
            .subscribe({
                    next: (newSubSector) => {
                        this.subSectors$ = this.sectorService.getSubSectors(this.sectorId);
                        this.selectedSubSector = newSubSector;
                        this.selectedSubSectorChange.emit(this.selectedSubSector);
                    }
                }
            );
    }
}
