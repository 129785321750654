import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '@core/services/global/dialog.service';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatSort} from '@angular/material/sort';
import {SubSink} from 'subsink';
import {
    CustomerReferencesDialogData
} from '@customer/components/customer-detail/hourly-rate/customer-references-dialog/customer-references-dialog.component';
import {CustomerReferenceModel} from '@customer/models';
import {CustomerMiscService} from '@customer/customer-misc.service';

@Component({
    selector: 'omt-customer-references',
    templateUrl: './customer-references.component.html',
    styleUrls: ['./customer-references.component.scss']
})
export class CustomerReferencesComponent implements OnInit {
    @Input()
    set customerId(val: number) {
        this._customerId = val;
    }

    get customerId(): number {
        return this._customerId;
    }

    @ViewChild(MatSort) set content(content: MatSort) {
        this.sort = content;
        if (this.sort) {
            this.dataSource.sort = this.sort;
        }

    }

    private subs = new SubSink();
    private sort: MatSort;

    displayedColumns = ['name', 'actions'];
    private _customerId: number;
    refs: CustomerReferenceModel[] = [];
    dataSource: MatTableDataSource<CustomerReferenceModel>;

    isLoading = true;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private readonly dialogService: DialogService,
        private readonly customerMiscService: CustomerMiscService) {
    }

    ngOnInit(): void {
        this.loadRefs();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ API
    // -----------------------------------------------------------------------------------------------------

    loadRefs(): void {
        this.subs.sink = this.customerMiscService.readCustomerReference(this._customerId).subscribe((x) => {
            this.refs = x;
            this.tableConfiguration();
            this.isLoading = false;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Data
    // -----------------------------------------------------------------------------------------------------

    tableConfiguration(): void {
        if (!this.refs || this.refs.length === 0) {
            return;
        }

        this.dataSource = new MatTableDataSource<CustomerReferenceModel>();

        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: CustomerReferenceModel, sortHeaderId) => item[sortHeaderId];

        setTimeout(() => this.dataSource.data = this.refs, 1);
    }

    private addRef(newRef: CustomerReferenceModel): void {
        this.refs = [...this.refs, newRef];
        this.tableConfiguration();
    }


    openDialog(customerRef?: CustomerReferenceModel): void {
        const dialogData = new CustomerReferencesDialogData();
        dialogData.customerId = this._customerId;
        dialogData.customerRef = customerRef;
        const dialogRef = this.dialogService.openCustomerReferenceDialog(dialogData);

        this.subs.sink = dialogRef.componentInstance.addedCustomerRef.subscribe((x) => {
            this.addRef(x);
        });

        this.subs.sink = dialogRef.componentInstance.updatedCustomerRef.subscribe((x) => {
            let foundRef = this.refs.find((z) => z.id === x.id);
            foundRef = x;
        });
    }

    deleteRef(customerRef: CustomerReferenceModel): void {
        this.subs.sink = this.customerMiscService.deleteCustomerReference(customerRef.id).subscribe((x) => {
            this.refs = this.refs.filter((y) => y.id !== x.id);
        });
    }
}
