<div class="note-container">
  <h2>{{ 'WAREHOUSE.NOTES' | translate }}</h2>

  @if (canCreate) {
    <div class="mb-2" fxLayout="row" fxLayout.lt-lg="column">
      @if (hasRichEditor) {
        <ng-container *ngTemplateOutlet="richEditor"></ng-container>
      } @else {
        <ng-container *ngTemplateOutlet="normalInput"></ng-container>
      }
    </div>
  }

  <ng-template #normalInput>
    <div class="input-group">
      @if (hasTitle) {
        <input
          class="redesign input-group-prepend"
          fxFlex="20"
          fxFlex.lt-lg="50"
          type="text"
          [maxLength]="titleMaxLength"
          [(ngModel)]="newNote.title"
          [placeholder]="'CUSTOMER.SUBJECT' | translate"
        />
      }

      @if (hasType && types?.length > 0) {
        <ng-container *ngTemplateOutlet="typeSelection"></ng-container>
      }

      <input
        type="text"
        class="redesign input-group-prepend w-100-p"
        [ngClass]="{'input-group-append': hasTitle}"
        (keyup.enter)="create()"
        [(ngModel)]="newNote.text"
        [maxLength]="noteMaxLength"
        [placeholder]="'CUSTOMER.ADD_A_NOTE' | translate"
      />
      <button (click)="create()" class="btn background-black input-group-append" [disabled]="disabled" fxFlex.lt-lg="100" type="button">
        <i class="fas fa-paper-plane text-white"></i>
      </button>

    </div>
  </ng-template>

  <ng-template #richEditor>
    <div fxLayout="column" fxLayoutGap="1rem" class="">
      @if (hasTitle) {
        <input
          class="redesign"
          type="text"
          [maxLength]="titleMaxLength"
          [(ngModel)]="newNote.title"
          [placeholder]="'CUSTOMER.SUBJECT' | translate"
        />
      }

      @if (hasType && types?.length > 0) {
        <ng-container *ngTemplateOutlet="typeSelection"></ng-container>
      }

      <omt-editor [maxLength]="noteMaxLength" [(value)]="newNote.text" [placeholder]="'CUSTOMER.ADD_A_NOTE' | translate" [editMode]="true"></omt-editor>

      <div fxLayout="row" fxLayoutAlign="end">
        <omt-button type="raised" color="primary" (click)="create()" [disabled]="disabled">{{ 'GLOBAL.ADD' | translate }}</omt-button>
      </div>
    </div>
  </ng-template>

  <ng-template #typeSelection>
    <div>
      <ng-select [items]="types" [placeholder]="'GLOBAL.TYPE' | translate" [(ngModel)]="newNote.type" bindLabel="name" bindValue="value"></ng-select>
    </div>
  </ng-template>

  <ng-template #defaultTypeTemplate let-note>
    <span class="badge badge-outline badge-outline-info">{{ note.type }}</span>
  </ng-template>

  @if (!notes || !(notes.length > 0)) {
    <div>{{ 'CUSTOMER.NO_NOTES_AVAILABLE' | translate }}</div>
  }

  @for (note of notes; track note) {
    <div class="note-row" fxLayout="row" fxLayoutAlign="space-between start">
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
        <omt-avatar [avatarUrl]="note.createdUser.avatarUrl" [matTooltip]="note.createdUser.username" size="36"></omt-avatar>
        <div fxLayout="column" fxLayoutGap="3px">
          <div fxLayout="row" fxLayoutGap="2rem" fxLayoutAlign="start center">
            @if (hasTitle) {
              <strong>
                {{ note.title ?? 'Leerer Betreff' }}
              </strong>
            }
            <span class="text-muted">
              {{ note.createdAt | germanDateFormat }}
            </span>
          </div>
          @if (hasRichEditor) {
            <omt-editor [(value)]="note.text" [editMode]="false" class="rounded"></omt-editor>
          } @else {
            <span>
              {{ note.text }}
            </span>
          }
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2rem">
        @if (hasType && note.type != null) {
          <div>
            <ng-container *ngTemplateOutlet="typeTemplate ?? defaultTypeTemplate; context: {$implicit: note}"></ng-container>
          </div>
        }
        <omt-delete-icon *omtShowOwner="note" (delete)="delete(note)" [matTooltip]="'CUSTOMER.DELETE_NOTE' | translate"></omt-delete-icon>
      </div>
    </div>
  }
</div>
