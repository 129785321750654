<ng-select
  #ngSelect
  class="redesign entity-dropdown"
  [placeholder]="'CUSTOMER.TERM_OF_PAYMENT' | translate"
  [items]="paymentConditions$ | async"
  bindLabel="name"
  [(ngModel)]="paymentCondition"
  appendTo="body"
  [loading]="isLoading"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [clearable]="clearable"
  [multiple]="multiple"
  [readonly]="readonly"
  [disabled]="disabled"
>
  <ng-template ng-footer-tmp *omtHasPermission="'kundenVerwaltung'">
    <omt-dropdown-footer tooltip="Neue Zahlungsbedingung erstellen" (addClick)="openDialog(); ngSelect.close()"></omt-dropdown-footer>
  </ng-template>

  <ng-template ng-notfound-tmp>
    <omt-dropdown-no-entries></omt-dropdown-no-entries>
  </ng-template>
</ng-select>
