import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentConditionDialogComponent} from '@management/customer/payment-condition/payment-condition-dialog.component';
import {FlexModule} from '@ngbracket/ngx-layout';
import {DialogHostModule} from '@libs/ui/src';
import {FormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [PaymentConditionDialogComponent],
    imports: [
        CommonModule,
        FlexModule,
        DialogHostModule,
        FormsModule,
        MatButtonModule,
        TranslateModule
    ]
})
export class PaymentConditionDialogModule {
}
