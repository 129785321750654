import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {SubSink} from 'subsink';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {DialogService} from '@core/services/global/dialog.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {
    CustomerHourlyRateDialogComponent,
    CustomerHourlyRateDialogData
} from '@customer/components/customer-detail/hourly-rate/customer-hourly-rate-dialog/customer-hourly-rate-dialog.component';
import {WorkLogTypeByCustomerModel} from '@ticket/models';
import {WorklogTypeService} from '@management/tickets/work-log-types/worklog-type.service';

@Component({
    selector: 'omt-customer-hourly-rate',
    templateUrl: './customer-hourly-rate.component.html',
    styleUrls: ['./customer-hourly-rate.component.scss']
})
export class CustomerHourlyRateComponent implements OnInit, OnDestroy {
    @Input()
    set customerId(val: number) {
        this._customerId = val;
    }

    @ViewChild(MatSort) set content(content: MatSort) {
        this.sort = content;
        if (this.sort) {
            this.dataSource.sort = this.sort;
        }

    }

    private subs = new SubSink();
    private sort: MatSort;
    private _customerId: number;

    workLogRates: WorkLogTypeByCustomerModel[];
    isLoading = false;
    dataSource: MatTableDataSource<WorkLogTypeByCustomerModel>;
    displayedColumns = ['name', 'hourly', 'maintenanceVolume', 'actions'];

    /*--------------------------------------------------------------
   # Lifecycle
   --------------------------------------------------------------*/

    constructor(
        private readonly worklogTypeService: WorklogTypeService,
        private readonly dialogService: DialogService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.loadWorkLogTypes();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    tableConfiguration(): void {
        if (!this.workLogRates || this.workLogRates.length === 0) {
            return;
        }

        this.dataSource = new MatTableDataSource(this.workLogRates);

        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = ((item: WorkLogTypeByCustomerModel, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'hourly':
                    return item.hourlyRate;

                default:
                    return item[sortHeaderId];
            }
        });
    }

    /*--------------------------------------------------------------
   # Load
   --------------------------------------------------------------*/

    loadWorkLogTypes(): void {
        this.isLoading = true;
        this.subs.sink = this.worklogTypeService.readWorkLogTypesByCustomer(this._customerId).subscribe((data: WorkLogTypeByCustomerModel[]) => {
            this.workLogRates = data;
            this.isLoading = false;
            this.tableConfiguration();
        });
    }

    /*--------------------------------------------------------------
   # Actions
   --------------------------------------------------------------*/

    remove(workLogRate: WorkLogTypeByCustomerModel): void {
        const dialogRef = this.dialogService.openConfirmDialog();

        this.subs.sink = dialogRef.componentInstance.deleteClicked.subscribe((clicked) => {
            if (clicked) {
                this.worklogTypeService.deleteWorkLogTypeByCustomer(workLogRate.id).subscribe(() => {
                    this.workLogRates = this.workLogRates.filter((x) => x.id !== workLogRate.id);
                    this.tableConfiguration();
                });
            }
        });
    }

    /*--------------------------------------------------------------
   # Modal Section
   --------------------------------------------------------------*/

    openDialog(workLogRate?: WorkLogTypeByCustomerModel): void {
        const dialogData = new CustomerHourlyRateDialogData();
        dialogData.editItem = workLogRate;
        dialogData.customerId = this._customerId;

        const dialogRef = this.dialog.open(CustomerHourlyRateDialogComponent, {
            width: '750px',
            data: dialogData,
            disableClose: true,
            panelClass: ['mat-dialog-overflow', 'dialog-0-p']
        });

        this.subs.sink = dialogRef.componentInstance.newWorkLogRate.subscribe((workLogTypeByCustomerModel: WorkLogTypeByCustomerModel) => {
            this.workLogRates.push(workLogTypeByCustomerModel);
            this.tableConfiguration();
        });

        this.subs.sink = dialogRef.componentInstance.updatedWorkLogRate.subscribe((workLogTypeByCustomerModel: WorkLogTypeByCustomerModel) => {
            this.workLogRates.filter((x) => x.id === workLogTypeByCustomerModel.id)[0] = workLogTypeByCustomerModel;
            this.tableConfiguration();
        });
    }
}
