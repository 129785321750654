import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CustomerCategoryModel} from '@customer/models';
import {CustomerMiscService} from '@customer/customer-misc.service';
import {DialogService} from '@core/services/global/dialog.service';
import {EntityDropdown} from '@core/components/entity-dropdown/entity-dropdown.component';

@Component({
    selector: 'omt-customer-category-dropdown',
    templateUrl: './customer-category-dropdown.component.html',
    styleUrls: ['./customer-category-dropdown.component.scss']
})
export class CustomerCategoryDropdownComponent extends EntityDropdown {
    @Input() category: CustomerCategoryModel;
    @Output() categoryChange = new EventEmitter<CustomerCategoryModel>();

    categories$ = this.miscService.readCustomerCategory();

    constructor(private readonly miscService: CustomerMiscService,
                private readonly dialogService: DialogService) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    openDialog(): void {
        const dialogRef = this.dialogService.openCreateCustomerCategoryDialog();

        this.subs.sink = dialogRef.componentInstance.newCategory.subscribe((data) => {
            this.categories$ = this.miscService.readCustomerCategory();
            this.category = {...data};
            this.categoryChange.emit(this.category);
        });
    }
}
