<omt-dialog-host [title]="isNewUser ? ('CUSTOMER.ADD_NEW_USER' | translate) : ('CUSTOMER.EDIT_USER' | translate)" [bodyPadding]="false" (closeClicked)="closeDialog()">
  <div dialog-content fxLayout="column" id="customer">
    <div class="section-header">
      {{ 'CUSTOMER.LOGIN_DATA' | translate }}
    </div>

    <div class="modal-section" fxLayout="column" fxLayoutGap="11px">
      <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="1rem">
        <div fxLayout="column" fxFlex="50">
          <label>E-Mail</label>
          <input #mail="ngModel" class="redesign" [(ngModel)]="customerUser.email" type="email" email placeholder="E-Mail"/>
          @if (!mail.hasError('required') && mail.hasError('email')) {
            <mat-error class="mb-12">
              {{ 'LOGIN.ENTER_VALID_EMAIL' | translate }}
            </mat-error>
          }
        </div>

        <mat-slide-toggle [(ngModel)]="customerUser.activated">{{ 'CUSTOMER.ACTIVATED' | translate }}</mat-slide-toggle>
      </div>

      @if (!isNewUser) {
        <h4 class="mt-3 mb-2"><strong>{{ 'CUSTOMER.NEW_PASSWORD_INFO' | translate }}</strong></h4>
      }

      <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="1rem">
        <div fxLayout="column" fxFlex="50">
          <label>{{ 'CUSTOMER.PASSWORD' | translate }}</label>
          <input class="redesign" [(ngModel)]="customerUser.passwordHash" type="password" [placeholder]="'CUSTOMER.PASSWORD' | translate"/>
        </div>
      </div>

      <div fxLayout="row">
        <div fxLayout="column" fxFlex="50">
          <label>{{ 'CUSTOMER.REPEAT_PASSWORD' | translate }}</label>
          <input class="redesign" [(ngModel)]="passwordRepeated" type="password" [placeholder]="'CUSTOMER.PASSWORD' | translate"/>
        </div>
      </div>
    </div>
  </div>

  <div dialog-footer class="modal-actions">
    <button mat-fab color="primary" (click)="save()" [disabled]="mail.invalid || (isNewUser && customerUser.passwordHash !== passwordRepeated)">
      <i class="fas fa-check fa-2x"></i>
    </button>
  </div>
</omt-dialog-host>
