import {BaseModel} from '@core/models/base/base.model';

export class CustomerUserModel extends BaseModel {
    customerId: number;
    customerName: string;
    passwordHash = '';
    email: string;
    activated: boolean;
}

