import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NoteComponent} from './note.component';
import {DeleteIconModule} from '@core/components/delete-icon/delete-icon.module';
import {CoreModule} from '@core/core.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {AvatarModule} from '@libs/ui/src/components/avatar/avatar.module';
import {FlexModule} from '@ngbracket/ngx-layout';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {EditorModule} from '@core/components/editor/editor.module';
import {ButtonComponent} from '@libs/ui/src/components/button/button.component';
import {NgSelectModule} from '@ng-select/ng-select';


@NgModule({
    declarations: [
        NoteComponent
    ],
    exports: [
        NoteComponent
    ],
    imports: [
        CommonModule,
        DeleteIconModule,
        CoreModule,
        MatTooltipModule,
        AvatarModule,
        FlexModule,
        FormsModule,
        TranslateModule,
        EditorModule,
        ButtonComponent,
        NgSelectModule
    ]
})
export class NoteModule {
}
