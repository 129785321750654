import {Component, Input, OnInit} from '@angular/core';
import {PaymentTypeModel} from '@core/models/user/payment-type.model';
import {CustomerModel, CustomerPaymentInfoModel, PaymentConditionModel} from '@customer/models';
import {WarehousePriceGroupModel} from '@warehouse/master-data/price-groups/warehouse-price-group.model';
import {WarehouseService} from '@warehouse/warehouse.service';
import {CustomerMiscService} from '@customer/customer-misc.service';

@Component({
    selector: 'omt-customer-price-groups',
    templateUrl: './customer-price-groups.component.html',
    styleUrls: ['./customer-price-groups.component.scss']
})
export class CustomerPriceGroupsComponent implements OnInit {
    // -----------------------------------------------------------------------------------------------------
    // @ Declarations
    // -----------------------------------------------------------------------------------------------------

    @Input() customer: CustomerModel;
    @Input() editMode: boolean;
    priceGroups: WarehousePriceGroupModel[] = [];
    conditions: PaymentConditionModel[] = [];
    types: PaymentTypeModel[] = [];

    constructor(
        private readonly warehouseService: WarehouseService,
        private readonly miscService: CustomerMiscService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.warehouseService.readPriceGroups().subscribe((data: WarehousePriceGroupModel[]) => {
            this.priceGroups = data;
        });

        if (this.customer.paymentInfo == null) {
            this.customer.paymentInfo = new CustomerPaymentInfoModel();
        }


        this.loadPaymentConditions();
        this.loadPaymentTypes();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Load
    // -----------------------------------------------------------------------------------------------------

    loadPaymentConditions(): void {
        this.miscService.readPaymentCondition().subscribe((data) => {
            this.conditions = data;
        });
    }

    loadPaymentTypes(): void {
        this.miscService.readPaymentType().subscribe((data) => {
            this.types = data;
        });
    }
}
