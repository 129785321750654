import {Injectable} from '@angular/core';
import {CustomerUserModel} from '@customer/components/customer-detail/customer-accounts/customer-user.model';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {ApiService} from '@core/services/api.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerUserHttpService {

    route = 'api/CustomerUser/';

    constructor(private apiService: ApiService) {
    }

    getAll = (customerId: number): Observable<CustomerUserModel[]> => this.apiService.get<CustomerUserModel[]>(this.route + 'GetAll', new HttpParams().set('customerId', customerId.toString()));

    create(body: CustomerUserModel): Observable<CustomerUserModel> {
        return this.apiService.post<CustomerUserModel>(this.route + 'Create', body);
    }

    update(body: CustomerUserModel): Observable<CustomerUserModel> {
        return this.apiService.put<CustomerUserModel>(this.route + 'Update', body);
    }

    delete(uId: number): Observable<CustomerUserModel> {
        return this.apiService.delete<CustomerUserModel>(this.route + 'Delete', new HttpParams().set('uId', uId.toString()));
    }

    disable(uId: number): Observable<CustomerUserModel> {
        return this.apiService.get<CustomerUserModel>(this.route + 'Disable', new HttpParams().set('uId', uId.toString()));
    }
}
