@if (isLoading) {
  <omt-spinner></omt-spinner>
}

@if (!isLoading) {
  <div id="customer" class="page-layout simple redesign carded fullwidth tabbed">
    <div *headerActions ngProjectAs="actions" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="flex-end">
      <omt-favorite-button buttonType="mini-fab" [entityId]="customerId" type="customer"></omt-favorite-button>
      <button mat-mini-fab color="primary" type="button" (click)="editMode = !editMode" [matTooltip]="'GLOBAL.EDIT' | translate">
        <i class="fa fa-pen text-white"></i>
      </button>
      @if (editMode) {
        <button mat-mini-fab color="primary" type="button" (click)="saveCustomer()" [matTooltip]="'GLOBAL.SAVE' | translate">
          <i class="fa fa-save text-white"></i>
        </button>
      }
    </div>
    @if (customer) {
      <div class="content-spacer pt-0">
        <mat-tab-group class="box-shadow">
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-address-card"></i>
              <span>{{ 'CUSTOMER.CONTACT' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
              <omt-customer-contact [customer]="customer" [editMode]="editMode"></omt-customer-contact>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-user-friends"></i>
              <span>{{ 'CUSTOMER.CONTACT_PERSON' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
              <omt-customer-reference-person [customer]="customer" [editMode]="editMode"></omt-customer-reference-person>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-tasks"></i>
              <span>Tickets</span>
            </ng-template>
            <ng-template matTabContent>
              <omt-issue-list [customerId]="customerId"></omt-issue-list>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-money-bill"></i>
              <span>{{ 'CUSTOMER.PAYMENT_DETAILS' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
              <omt-customer-price-groups [customer]="customer" [editMode]="editMode"></omt-customer-price-groups>
            </ng-template>
          </mat-tab>
          @if (canAccessManagement()) {
            <mat-tab>
              <ng-template mat-tab-label>
                <i class="fas fa-cog"></i>
                <span>{{ 'CUSTOMER.USER_ACCOUNTS' | translate }}</span>
              </ng-template>
              <ng-template matTabContent>
                <omt-customer-accounts [customerId]="customer.id"></omt-customer-accounts>
              </ng-template>
            </mat-tab>
          }
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-paperclip"></i>
              <span>{{ 'CUSTOMER.ATTACHMENTS' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
              <omt-customer-attachment [customerId]="customerId"></omt-customer-attachment>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-address-card"></i>
              <span>{{ 'CUSTOMER.NOTES' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
              <omt-customer-note></omt-customer-note>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="fas fa-address-card"></i>
              <span> {{ 'MANAGEMENT.PROJECTS' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
              <omt-project [customerId]="customerId"></omt-project>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <i class="far fa-clock"></i>
              <span> {{ 'GLOBAL.SETTINGS' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
              <omt-hourly-rate-host [customerId]="customerId"></omt-hourly-rate-host>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    }
  </div>
}
