<mat-card class="p-3 vh-70" fxLayout="column">
  <omt-note [canCreate]="true"
            [notes]="customerNotes"
            (deleteClicked)="onDelete($event.id)"
            [hasTitle]="true"
            [noteMaxLength]="noteMaxLength"
            [titleMaxLength]="titleMaxLength"
            [hasRichEditor]="true"
            [hasType]="true"
            [types]="noteTypes"
            [typeTemplate]="typeTemplate"
            (createdNote)="onCreate($event)"></omt-note>

  <ng-template #typeTemplate let-note>
    <span class="badge badge-outline badge-outline-info">
      @switch (note.type) {
        @case (customerNoteType.VisitReport) {
          {{ 'TICKET.VISIT_REPORT' | translate }}
        }
        @case (customerNoteType.Note) {
          {{ 'TICKET.NOTE' | translate }}
        }
        @case (customerNoteType.Task) {
          {{ 'TICKET.TASK' | translate }}
        }
        @case (customerNoteType.Resubmission) {
          {{ 'TICKET.RESUBMISSION' | translate }}
        }
        @case (customerNoteType.PhoneEntry) {
          {{ 'TICKET.PHONE_ENTRY' | translate }}
        }
        @default {

        }
      }
    </span>
  </ng-template>
</mat-card>
