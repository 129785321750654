import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {DropzoneConfigInterface, DropzoneDirective} from 'ngx-dropzone-wrapper';
import {environment} from '@env/environment';
import {CustomerAttachmentDialogComponent} from '../../customer-attachment-dialog/customer-attachment-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {CustomerDocumentModel} from '@customer/models';
import {CustomerDocumentService} from '@customer/customer-document.service';

@Component({
    selector: 'omt-customer-attachment',
    templateUrl: './customer-attachment.component.html',
    styleUrls: ['./customer-attachment.component.scss']
})
export class CustomerAttachmentComponent implements OnInit {
    // -----------------------------------------------------------------------------------------------------
    // @ Declarations
    // -----------------------------------------------------------------------------------------------------

    @Input() customerId: number;
    @Output() uploadEmitter = new EventEmitter();
    @ViewChild(DropzoneDirective, {static: true}) drpzone: DropzoneDirective;

    environment = environment;
    documents: CustomerDocumentModel[] = [];
    displayedColumns = ['name', 'size', 'note', 'updatedAt', 'actions'];
    dataSource: MatTableDataSource<CustomerDocumentModel>;

    private sort: MatSort;
    private paginator: MatPaginator;

    constructor(
        public dialog: MatDialog,
        private readonly customerDocService: CustomerDocumentService,
        private readonly translateService: TranslateService) {
    }

    @ViewChild(MatSort, {static: false}) set content(content: MatSort) {
        this.sort = content;
        if (this.sort) {
            this.dataSource.sort = this.sort;
        }

    }

    dropzoneConfig: DropzoneConfigInterface = {acceptedFiles: 'image/*,application/pdf'};

    @ViewChild(MatPaginator, {static: false}) set matPaginator(matPaginator: MatPaginator) {
        this.paginator = matPaginator;
        if (this.paginator) {
            this.dataSource.paginator = this.paginator;
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.dropzoneConfig.url = environment.apiUrl + 'api/Customer/CreateCustomerDocument?customerId=' + this.customerId;
        this.loadDocuments();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Load
    // -----------------------------------------------------------------------------------------------------

    loadDocuments(): void {
        this.customerDocService.readCustomerDocuments(this.customerId).subscribe((data) => {
            this.documents = data;
            this.tableConfiguration();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    tableConfiguration(): void {
        this.dataSource = new MatTableDataSource(this.documents);

        this.dataSource.sortingDataAccessor = (item: any, property) => {
            switch (property) {
                default:
                    return item[property];
            }
        };

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    deleteDocument(id: number): void {
        this.customerDocService.deleteCustomerDocument(id).subscribe((data) => {
            this.documents = this.documents.filter((x) => x.id !== data.id);
            this.tableConfiguration();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    openDialog(doc: CustomerDocumentModel): void {
        const dialogRef = this.dialog.open(CustomerAttachmentDialogComponent, {
            width: '500px',
            data: doc,
            disableClose: true,
            panelClass: ['mat-dialog-overflow', 'dialog-0-p']
        });

        dialogRef.componentInstance.updatedDocument.subscribe((data) => {
            let foundDoc = this.documents.find((x) => x.id === data.id);
            foundDoc = {...foundDoc, ...data};
            this.tableConfiguration();
        });
    }
}
