import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserRoleModel} from '@core/models/user/userrole.model';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {CustomerModel, CustomerPaymentInfoUpdateRequest, CustomerReferencePersonModel, CustomerUpdateRequest} from '@customer/models';
import {CustomerService} from '@customer/customer.service';
import {HeaderService} from '@core/components/header/header.service';
import {DetailPageHeaderDefaultConfig} from '@core/components/header/header.config';
import {forkJoin, Observable} from 'rxjs';
import {PARAM_KEY_ID, ROUTE_PARAM_TOKEN, routeParamFactory} from '@libs/ui/src';
import {SubSink} from 'subsink';


@Component({
    selector: 'omt-customer-detail',
    templateUrl: './customer-detail.component.html',
    styleUrls: ['./customer-detail.component.scss'],
    providers: [
        {
            provide: ROUTE_PARAM_TOKEN,
            useFactory: routeParamFactory,
            deps: [ActivatedRoute, PARAM_KEY_ID]
        }
    ]
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
    customer: CustomerModel;
    refPersons: CustomerReferencePersonModel[] = [];
    customerId: number;
    editMode = false;
    isLoading = true;
    userRoles: UserRoleModel;

    private subs = new SubSink();

    constructor(
        @Inject(ROUTE_PARAM_TOKEN) private readonly id$: Observable<string>,
        private readonly customerService: CustomerService,
        {currentUser}: AuthenticationService,
        private readonly headerService: HeaderService
    ) {
        this.userRoles = currentUser.userRoles;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.subs.sink = this.id$.subscribe((id) => {
            this.customerId = +id;
            this.load();
        });

        this.headerService.config = DetailPageHeaderDefaultConfig;
        this.editMode = false;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Load
    // -----------------------------------------------------------------------------------------------------

    load(): void {
        this.subs.sink = forkJoin([this.customerService.getCustomerById(this.customerId), this.customerService.getCustomerReferencePersons(this.customerId)])
            .subscribe((data) => {
                [this.customer, this.refPersons] = data;
                this.headerService.setTitle(this.customer.displayName);
                this.customer.referencePersons = this.refPersons;
                this.isLoading = false;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Actions
    // -----------------------------------------------------------------------------------------------------

    saveCustomer(): void {
        const req = new CustomerUpdateRequest(
            this.customer.id,
            this.customer.name,
            this.customer.city,
            this.customer.postcode,
            this.customer.street,
            this.customer.houseNumber,
            this.customer.country,
            this.customer.taxNumber,
            this.customer.email,
            this.customer.webPage,
            this.customer.phone,
            this.customer.fax,
            this.customer.disabled,
            this.customer.avatarUrl,
            this.customer.invoiceLastName,
            this.customer.invoiceFirstName,
            this.customer.invoicePostcode,
            this.customer.invoiceStreet,
            this.customer.invoiceHouseNumber,
            this.customer.invoiceCountry,
            this.customer.invoiceCity,
            this.customer.firstName,
            this.customer.lastName,
            this.customer.affix,
            this.customer.mobilePhone,
            this.customer.matchCode,
            this.customer.newsletter,
            this.customer.category?.id,
            this.customer.salutation?.id,
            this.customer.numberOfEmployees,
            this.customer.numberOfComputerWorkplaces,
            this.customer.yearlyRevenue,
            this.customer.sector?.id ?? this.customer.sectorId,
            this.customer.subSector?.id ?? this.customer.subSectorId,
            new CustomerPaymentInfoUpdateRequest(
                this.customer.paymentInfo?.warehousePriceGroupId,
                this.customer.paymentInfo?.debtorAccount,
                this.customer.paymentInfo?.discount,
                this.customer.paymentInfo?.bank,
                this.customer.paymentInfo?.iban,
                this.customer.paymentInfo?.bic,
                this.customer.paymentInfo?.paymentTypeId,
                this.customer.paymentInfo?.paymentConditionId
            )
        );

        this.subs.sink = this.customerService.update(req).subscribe((updated) => {
            this.editMode = false;
            this.customer = {...updated};
        });
    }

    canAccessManagement = (): boolean => this.userRoles.kundenVerwaltung;
}
